// @flow

import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import { useLocale, addLocale, t } from "ttag";

import { PageContent, Section } from "@containers";
import {
    AgencyContactForm,
    AgencyHeroImage,
    AgencyInformation,
    AgencyMeta,
} from "@components";
import { AgencyHeader, UspTiles } from "@2po-c21/components";
import { AgencyPropertiesDisplay } from "@components";
import Render from "../components/Contentful";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { EVENT_MAP, type PageImpressionParams } from "../models/events";
import { TrackPageImpression } from "../components/Tracker/Tracker";
import { Slider } from "../components/TrustPilot";
import features from "../utils/features";

const JOBS_LINK = "jobs.century21.be";

const AgencyPageTemplate = ({
    data: {
        contentfulNavigation: { navigationItems },
        c21Agency,
        agencyFallbackImage,
        agencyHeaderFallbackImage,
        c21Agency: {
            name,
            address,
            phoneNumber,
            headerImages,
            agencyHeroImage,
            api_id,
            accountNumber,
            jobId,
        },
        footerItems,
        c21Translation: { translations },
        contentfulWebLandingPage: { pageContent },
        contentfulUspBlock: { uspItems },
    },
    pageContext: { locale, translationSlugs, country },
}: *) => {
    addLocale(locale, translations);
    useLocale(locale);
    const gtmDispatcher = useGTMDispatch();

    const agencyImage =
        (headerImages && headerImages.length && headerImages[0]) ||
        agencyHeaderFallbackImage.childImageSharp;

    const heroImage =
        (agencyHeroImage && agencyHeroImage.length && agencyHeroImage[0]) ||
        null;

    const LinkComponent = ({ children }: *) => (
        <Link to={`/${locale}/${t`slug.estimation-tool`}?agency=${api_id}`}>
            {children}
        </Link>
    );

    const transformNavItems = (navItems) => {
        const jobsItem = navItems.find((item) =>
            item.link?.externalLink?.includes(JOBS_LINK),
        );

        jobsItem.link.externalLink += jobId;

        return navItems;
    };

    useEffect(() => {
        const params: PageImpressionParams = {
            agency: {
                id: c21Agency.api_id,
                name: c21Agency.name,
                postalcode: c21Agency.address.postalCode,
                city: c21Agency.address.city,
                country: c21Agency.address.country,
            },
            agencypage: "true",
            language: locale,
            pagename: "agency",
            typetemplate: "agency",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);

    return (
        <PageContent
            locale={locale}
            navigationItems={transformNavItems(navigationItems)}
            translationSlugs={translationSlugs}
            footerItems={footerItems}
            agency={c21Agency}
            country={country}
        >
            <AgencyMeta
                agency={c21Agency}
                translationSlugs={translationSlugs}
                locale={locale}
            />
            <AgencyHeader
                agency={{ name, address, phoneNumber }}
                image={{
                    // eslint-disable-next-line react/display-name
                    imageSrc: () => (
                        <Image
                            backgroundColor
                            fixed={agencyImage.fixed}
                            alt={agencyImage?.description}
                        />
                    ),
                    description: agencyImage?.description,
                }}
                {...(features.SHOW_ESTIMATION_TOOL && { LinkComponent })}
                btnTxt={t`agency.header.button.text`}
            />
            {heroImage && (
                <AgencyHeroImage
                    image={{
                        // eslint-disable-next-line react/display-name
                        imageSrc: () => (
                            <Image
                                backgroundColor
                                fluid={heroImage?.fluid}
                                alt={heroImage?.description}
                            />
                        ),
                        description: heroImage?.description,
                    }}
                    accountNumber={accountNumber}
                    locale={locale}
                />
            )}
            <Section>
                <AgencyPropertiesDisplay agency={c21Agency} />
            </Section>
            {Render(pageContent, locale)}
            <Section>
                <AgencyInformation
                    agency={c21Agency}
                    fallbackImage={agencyFallbackImage.childImageSharp}
                    country={country}
                />
            </Section>
            <AgencyContactForm
                agencyApiId={api_id}
                locale={locale}
                type={"agency"}
            />
            <Slider
                locale={locale}
                templateId={process.env.GATSBY_PUBLIC_TRUSTPILOT_CAROUSEL_ID}
                businessUnitId={
                    process.env.GATSBY_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID
                }
                reviewLanguages={"nl"}
                dataHeight={"240px"}
                dataWidth={"100%"}
                dataStars={"4,5"}
                dataTheme={"light"}
                dataTrustPilotId={accountNumber}
                dataSchemaType={"LocalBusiness"}
                dataAllowRobots
            />
            <UspTiles
                tiles={uspItems.map((item) => ({
                    data: item.linkTitle,
                    label: item.linkSubtitle?.linkSubtitle,
                }))}
            />
        </PageContent>
    );
};

export default AgencyPageTemplate;

export const query = graphql`
    query ($id: String!, $locale: String!, $country: String!) {
        contentfulNavigation(
            title: { eq: "Agency navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "common.text.language"
                    "common.text.show-more"
                    "common.filters.*"
                    "api.property.*"
                    "slug.estimation-tool"
                    "slug.property-*"
                    "slug.privacy-policy"
                    "slug.agency-overview"
                    "common.text.search"
                    "home.category-links"
                    "landing.blog.article"
                    "landing.step-block.step"
                    "agency.header.button.text"
                    "form.agency-contact.*"
                    "agency.*"
                    "title.agency *"
                    "meta.description.agency *"
                    "breadcrumbs.agency*"
                    "common.price.on-demand"
                ]
            )
        }
        contentfulUspBlock(
            internalLabel: { eq: "USP - Basic" }
            node_locale: { eq: $locale }
        ) {
            title
            uspItems {
                linkTitle
                linkSubtitle {
                    linkSubtitle
                }
                icon {
                    fixed(width: 100, height: 100) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
            }
        }
        c21Agency(id: { eq: $id }, locale: { eq: $locale }) {
            name
            api_id
            locale
            jobId
            phoneNumber
            facebookUrl
            location {
                longitude
                latitude
            }
            address {
                street
                postalCode
                number
                country
                city
                box
                countryCode
            }
            email
            images {
                fixed(height: 81, width: 140) {
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
            }
            agencyHeroImage: images {
                fluid(maxWidth: 1400) {
                    aspectRatio
                    maxWidth
                    sizes
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                }
                description
            }
            headerImages: images {
                fixed(width: 137, height: 137) {
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
                description
            }
            bivNumber
            baNumber
            companyNumber
            companyName
            accountNumber
            broker
            syndic
            management
        }
        contentfulWebLandingPage(
            node_locale: { eq: $locale }
            internalLabel: { eq: "Agency Page" }
            country: { eq: $country }
        ) {
            pageContent {
                ... on ContentfulQuickSearchBlock {
                    id
                    node_locale
                    background {
                        size
                        color
                        position
                    }
                    content {
                        contentTitle
                        contentSubtitle
                        squareImage {
                            fluid(maxWidth: 2000, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                    quickLinks {
                        type
                        __typename
                        linkItems {
                            ... on ContentfulLink {
                                id
                                linkTitle
                                icon {
                                    fixed(width: 100, height: 100) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                }
                                externalLink
                                contentfulInternalLink {
                                    ... on ContentfulWebLandingPage {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
                ... on ContentfulQuickLinkBlock {
                    id
                    type
                    title
                    hideStepsTitle
                    background {
                        size
                        color
                        position
                    }
                    linkItems {
                        ... on ContentfulLink {
                            id
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                            externalLink
                            linkTitle
                            linkSubtitle {
                                linkSubtitle
                            }
                            icon {
                                fixed(width: 100, height: 100) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                            }
                            image {
                                fixed(width: 217, height: 185) {
                                    width
                                    height
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                }
                                description
                            }
                        }
                        ... on ContentfulCollection {
                            internalLabel
                            query
                            content {
                                contentTitle
                                squareImage {
                                    fixed(width: 217, height: 185) {
                                        width
                                        height
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                    description
                                }
                            }
                        }
                        ... on ContentfulWebLandingPage {
                            id
                            slug
                            pageContent {
                                ... on ContentfulBlog {
                                    id
                                    title
                                    heroImage {
                                        fluid(maxWidth: 568, quality: 80) {
                                            ...GatsbyContentfulFluid_withWebp
                                        }
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
                ... on ContentfulImageInformationBlock {
                    id
                    node_locale
                    type
                    background {
                        size
                        color
                        position
                    }
                    backgroundColor
                    textColor
                    imagePosition
                    maxHeight
                    link {
                        linkTitle
                        externalLink
                        contentfulInternalLink {
                            ... on ContentfulWebLandingPage {
                                slug
                            }
                        }
                    }
                    content {
                        contentTitle
                        contentSubtitle
                        landscapeImage {
                            fluid(maxWidth: 2344, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                        squareImage {
                            fluid(maxWidth: 1800, quality: 80) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            description
                        }
                    }
                }

                ... on ContentfulBanner {
                    id
                    imageLink {
                        ... on ContentfulLink {
                            id
                            externalLink
                            contentfulInternalLink {
                                ... on ContentfulWebLandingPage {
                                    slug
                                }
                            }
                        }
                    }
                    desktopImage {
                        fluid(maxWidth: 2344, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        title
                        description
                    }
                    mobileImage {
                        fluid(maxWidth: 2344, quality: 80) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        title
                        description
                    }
                }

                ... on ContentfulBlog {
                    id
                    title
                    author {
                        name
                        link
                        image {
                            fixed(width: 100, height: 100) {
                                width
                                height
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                            }
                        }
                    }

                    content {
                        ... on ContentfulRichText {
                            childContentfulRichTextTextRichTextNode {
                                json
                            }
                            internal {
                                type
                            }
                        }
                    }

                    heroImage {
                        fluid(maxWidth: 1172) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                        description
                    }
                }
                ... on ContentfulRichText {
                    childContentfulRichTextTextRichTextNode {
                        json
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulUspBlock {
                    id
                    type
                    uspItems {
                        linkTitle
                        linkSubtitle {
                            linkSubtitle
                        }
                        icon {
                            fixed(width: 100, height: 100) {
                                width
                                height
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                            }
                        }
                    }
                    title
                }
            }
        }
        agencyHeaderFallbackImage: file(relativePath: { eq: "fallback.png" }) {
            childImageSharp {
                fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        agencyFallbackImage: file(relativePath: { eq: "fallback.png" }) {
            childImageSharp {
                fixed(width: 140, height: 81) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
